var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { height, maxHeight, maxWidth, width } from 'styled-system';
import ffhLogo from '../../../common/assets/images/fashionforhome.svg';
import h24LogoGif from '../../../common/assets/images/home-24-logo.gif';
import h24LogoSvg from '../../../common/assets/images/home-24-logo.svg';
import themedComponent from '../../../common/components/hoc/themedComponent';
import { FFH_IMG_HEIGHT, FFH_IMG_WIDTH, H24_IMG_HEIGHT, H24_IMG_WIDTH, } from '../../../common/constants/logo';
import { useLocalization } from '../../../common/providers/LocaleProvider';
import { trackLogoClick } from '../tracking';
export var ImageFormat;
(function (ImageFormat) {
    ImageFormat["SVG"] = "svg";
    ImageFormat["GIF"] = "gif";
})(ImageFormat || (ImageFormat = {}));
var LogoLink = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var LogoImg = styled.img(width, maxWidth, height, maxHeight);
var H24LogoSvg = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
H24LogoSvg.defaultProps = {
    src: h24LogoSvg,
    as: LogoImg,
    width: H24_IMG_WIDTH,
    maxWidth: H24_IMG_WIDTH,
    height: H24_IMG_HEIGHT,
    maxHeight: H24_IMG_HEIGHT,
};
var H24LogoGif = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
H24LogoGif.defaultProps = {
    src: h24LogoGif,
    as: LogoImg,
    width: ['89px', '99px', '123px'],
    maxWidth: ['89px', '99px', '123px'],
    height: ['35px', '39px', '49px'],
    maxHeight: ['35px', '39px', '49px'],
};
var FFHLogo = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
FFHLogo.defaultProps = {
    src: ffhLogo,
    as: LogoImg,
    width: FFH_IMG_WIDTH,
    maxWidth: FFH_IMG_WIDTH,
    height: FFH_IMG_HEIGHT,
    maxHeight: FFH_IMG_HEIGHT,
};
var getBrandLogo = function (imageFormat) {
    return themedComponent(imageFormat === ImageFormat.SVG ? H24LogoSvg : H24LogoGif, FFHLogo);
};
var Logo = function (_a) {
    var imageFormat = _a.imageFormat;
    var _b = useLocalization(), t = _b.t, prefix = _b.prefix;
    var BrandLogo = useMemo(function () { return getBrandLogo(imageFormat); }, [imageFormat]);
    return (React.createElement(LogoLink, { href: prefix(t('h24_home_url')), onClick: function () { return trackLogoClick(); } },
        React.createElement(BrandLogo, { "data-testid": "brand-logo", alt: t('h24_logo_alt_title') })));
};
export default Logo;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
