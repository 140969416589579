var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { space } from 'styled-system';
import ActionItem from './ActionIcons/ActionItem';
import Tooltip from './ActionIcons/MyAccountTooltip/Tooltip';
var RightSectionItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n\n  &:first-of-type {\n    margin-left: 0;\n  }\n"], ["\n  ", ";\n\n  &:first-of-type {\n    margin-left: 0;\n  }\n"])), space);
RightSectionItem.defaultProps = {
    ml: ['sp_0', 'sp_8', 'sp_16', 'sp_24'],
};
var ToolbarActionItem = function (_a) {
    var _b = _a.Subscription, Subscription = _b === void 0 ? function (_a) {
        var children = _a.children;
        return children({});
    } : _b, showTooltip = _a.showTooltip, isNewIcon = _a.isNewIcon, item = __rest(_a, ["Subscription", "showTooltip", "isNewIcon"]);
    return (React.createElement(RightSectionItem, null,
        React.createElement(Subscription, __assign({}, item), function (_a) {
            var value = _a.value;
            return (React.createElement(ActionItem, { id: item.id, icon: item.icon, label: item.label, url: item.url, count: value, countThreshold: item.countThreshold, desktopOnly: item.desktopOnly, isNewIcon: isNewIcon, showTooltip: showTooltip }, function (_a) {
                var shouldShowChildren = _a.shouldShowChildren, isActionItemActive = _a.isActionItemActive;
                return showTooltip && shouldShowChildren && isActionItemActive ? (React.createElement(Tooltip, { customer: item.customer })) : null;
            }));
        })));
};
export default ToolbarActionItem;
var templateObject_1;
