var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, display, flex, justifyContent, maxWidth, space } from 'styled-system';
import { FlexAlignItems, FlexJustifyContent, FlexWrap, FullWidthBoxShadow, Grid, GridItem, } from '../../common/components/Grid';
import Search from '../../common/components/Search/Search';
import { useMatchesViewport } from '../../common/hooks/media';
import { useLocalization } from '../../common/providers/LocaleProvider';
import { openSideNav } from '../../common/utils/navigation';
import LanguageSwitch from './LanguageSwitch';
import Logo, { ImageFormat } from './Logo/Logo';
import MenuButton from './MenuButton/MenuButton';
import ToolbarActionItem from './ToolbarActionItem';
import { getActionItems } from './utils';
var ToolbarGrid = styled(Grid)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
ToolbarGrid.defaultProps = {
    alignItems: FlexAlignItems.CENTER,
    justifyContent: FlexJustifyContent.SPACE_BETWEEN,
    flexWrap: [FlexWrap.WRAP, FlexWrap.NO_WRAP],
    py: ['sp_16', 'sp_12'],
};
var LeftSectionGridItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), flex, display, alignItems);
LeftSectionGridItem.defaultProps = {
    as: GridItem,
    w: 'auto',
    alignItems: 'center',
    display: 'flex',
    order: 1,
    flex: 'none',
};
var CenterSectionGridItem = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), flex, maxWidth);
CenterSectionGridItem.defaultProps = {
    as: GridItem,
    w: [1, 'auto'],
    order: [3, 2],
    // We are so specific with numbers because of IE
    flex: ['1 0 100%', '1 1 420px', null, '1 1 512px'],
};
var RightSectionGridItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), flex, display, justifyContent, space);
RightSectionGridItem.defaultProps = {
    as: GridItem,
    w: 'auto',
    ml: ['auto', 0],
    display: 'flex',
    order: [2, 3],
    justifyContent: 'flex-end',
    flex: 'none',
    pl: 'sp_0',
};
var Toolbar = function (_a) {
    var customer = _a.customer, searchParams = _a.searchParams, _b = _a.searchOptions, showIconOnMobile = _b.showIconOnMobile, _c = _b.withInputFilled, withInputFilled = _c === void 0 ? true : _c, restProps = __rest(_a, ["customer", "searchParams", "searchOptions"]);
    var getLanguages = useLocalization().getLanguages;
    var isLoggedIn = !!(customer === null || customer === void 0 ? void 0 : customer.isLoggedIn);
    var actionItemsProps = __assign({ customer: customer }, restProps);
    var actionItems = getActionItems(actionItemsProps);
    var matchesTablet = useMatchesViewport(false).matches.matchesTablet;
    var showOnlySearchIcon = !matchesTablet && showIconOnMobile;
    var searchProps = {
        params: searchParams,
        withInputFilled: withInputFilled,
    };
    return (React.createElement(ToolbarGrid, __assign({ "data-testid": "toolbar", legacyShopRebootMaxWidth: false }, (!showOnlySearchIcon && {
        fullWidthBoxShadow: FullWidthBoxShadow.BOTTOM,
    })),
        React.createElement(LeftSectionGridItem, null,
            React.createElement(MenuButton, { onClick: function () { return openSideNav(); } }),
            React.createElement(Logo, { imageFormat: ImageFormat.SVG })),
        React.createElement(CenterSectionGridItem, { maxWidth: [null, null, null, '920px'] }, !showOnlySearchIcon && React.createElement(Search, __assign({}, searchProps))),
        React.createElement(RightSectionGridItem, null,
            showOnlySearchIcon && React.createElement(Search, __assign({}, searchProps, { showOnlySearchIcon: true })),
            actionItems
                .filter(function (_a) {
                var auth = _a.auth, id = _a.id;
                return (typeof auth === 'undefined' || auth === isLoggedIn) &&
                    !(showOnlySearchIcon && id === 'userAccount');
            })
                .map(function (item) { return (React.createElement(ToolbarActionItem, __assign({}, item, { key: item.id, isNewIcon: showOnlySearchIcon }))); }),
            React.createElement(LanguageSwitch, { languages: getLanguages() }))));
};
export default Toolbar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
