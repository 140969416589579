var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CartSubscription, WishlistSubscription, } from '../../common/event-bus/EventSubscription';
export var ACTION_ITEMS = [
    {
        // INFO: We are using the same ids to trick React into
        // thinking that those items are the same
        id: 'userAccount',
        icon: 'Account',
        label: 'h24_register',
        url: 'h24_login_page_url',
        showTooltip: true,
        auth: false,
    },
    {
        id: 'userAccount',
        icon: 'Account',
        label: 'h24_my_account',
        url: 'h24_my_overview_url',
        showTooltip: true,
        auth: true,
    },
    {
        id: 'wishlist',
        icon: 'Wishlist',
        label: 'h24_wishlist',
        url: 'h24_wishlist_url',
        Subscription: WishlistSubscription,
    },
    {
        id: 'cart',
        icon: 'Cart',
        label: 'h24_cart',
        url: 'h24_cart_url',
        Subscription: CartSubscription,
        countThreshold: 0,
    },
];
export var getActionItems = function (_a) {
    var customer = _a.customer, cartCount = _a.cartCount, wishlistCount = _a.wishlistCount;
    return ACTION_ITEMS.map(function (actionItem) { return (__assign(__assign(__assign(__assign({}, actionItem), (actionItem.id === 'userAccount' && { customer: customer })), (actionItem.id === 'wishlist' && { initialCount: wishlistCount })), (actionItem.id === 'cart' && { initialCount: cartCount }))); });
};
