var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { display, fontSize, fontWeight, lineHeight, position, space } from 'styled-system';
import { Link } from '../../common/components/Link';
import { cursor, pointerEvents, textTransform } from '../../common/theme/system-utilities';
import { getUrlPrefix } from '../../common/utils/url';
import { trackLanguageSelectorClick } from './tracking';
var LanguageSwitchWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  align-self: flex-end;\n  flex: none;\n"], ["\n  ", ";\n  ", ";\n  align-self: flex-end;\n  flex: none;\n"])), display, space);
var languageSwitchWrapperStyles = {
    display: ['none', null, 'inline-flex'],
    ml: [null, 'sp_2', 'sp_12'],
};
var CurrentLanguage = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", "\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", "\n"])), pointerEvents, position, cursor, space, fontSize, lineHeight, fontWeight, textTransform, function (_a) {
    var index = _a.index, theme = _a.theme;
    return index !== 0 &&
        "\n    padding-left: ".concat(theme.space.sp_4, "px;\n\n    &::before {\n      content: '';\n      display: inline-block;\n      width: 1px;\n      height: 8px;\n      margin-right: ").concat(theme.space.sp_4, "px;\n      background-color: currentColor;\n    }\n    ");
});
var currentLanguageStyles = {
    fontWeight: 'bold',
    fontSize: 'fs_13',
    lineHeight: 'lh_20',
    position: 'relative',
    textTransform: 'capitalize',
};
var LanguageSelector = styled(CurrentLanguage)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var languageSelectorStyles = __assign(__assign({}, currentLanguageStyles), { as: Link, cursor: 'pointer', fontWeight: 'normal' });
var LanguageSwitch = function (_a) {
    var languages = _a.languages;
    if (!languages || (languages === null || languages === void 0 ? void 0 : languages.length) < 2) {
        return null;
    }
    return (React.createElement(LanguageSwitchWrapper, __assign({}, languageSwitchWrapperStyles), languages.map(function (_a, index) {
        var language = _a.language, pathPrefix = _a.pathPrefix, isCurrent = _a.isCurrent;
        return isCurrent ? (React.createElement(CurrentLanguage, __assign({ "data-testid": "current-language", key: language, index: index }, currentLanguageStyles), language)) : (React.createElement(LanguageSelector, __assign({ key: language, index: index, "data-testid": "language-selector-link", href: getUrlPrefix(pathPrefix, isCurrent), current: isCurrent, onClick: function () { return trackLanguageSelectorClick(language); } }, languageSelectorStyles), language));
    })));
};
export default LanguageSwitch;
var templateObject_1, templateObject_2, templateObject_3;
