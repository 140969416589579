var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Debounce from '../debounce';
import { withFirstTouch } from './hoc/withFirstTouch';
var Container = withFirstTouch('div');
var EventType;
(function (EventType) {
    EventType["Focus"] = "focus";
})(EventType || (EventType = {}));
var KeepFocusWithFirstTouch = /** @class */ (function (_super) {
    __extends(KeepFocusWithFirstTouch, _super);
    function KeepFocusWithFirstTouch(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { focused: false };
        _this.setFocused = _this.setFocused.bind(_this);
        _this.handleFirstTouch = _this.handleFirstTouch.bind(_this);
        _this.handleMouseChange = _this.handleMouseChange.bind(_this);
        _this.handleFocus = _this.handleFocus.bind(_this);
        _this.handleBlur = _this.handleBlur.bind(_this);
        _this.handleTouchEnd = _this.handleTouchEnd.bind(_this);
        _this.setContainerRef = _this.setContainerRef.bind(_this);
        _this.setInnerRef = _this.setInnerRef.bind(_this);
        _this.debouncedFocusChange = new Debounce(_this.onFocusChange, 1000 / 60, _this);
        _this.debouncedSetState = new Debounce(_this.setFocused, 150, _this);
        _this.container = null;
        _this.innerElem = null;
        return _this;
    }
    KeepFocusWithFirstTouch.prototype.setFocused = function (focused) {
        if (!focused &&
            this.container &&
            typeof this.container.resetFirstTouch === 'function') {
            this.container.resetFirstTouch();
        }
        this.setState(function (prevState) {
            return prevState.focused !== focused ? { focused: focused } : null;
        });
    };
    KeepFocusWithFirstTouch.prototype.handleFirstTouch = function (event) {
        if (!this.props.active) {
            return;
        }
        event.preventDefault();
        this.debouncedSetState.debounce(true);
    };
    KeepFocusWithFirstTouch.prototype.handleMouseChange = function (event) {
        if (!this.props.active) {
            return;
        }
        this.debouncedSetState.debounce(event.type === 'mouseenter');
    };
    KeepFocusWithFirstTouch.prototype.onFocusChange = function (eventType) {
        if (!this.props.active) {
            return;
        }
        this.debouncedSetState.debounce(eventType === EventType.Focus);
    };
    KeepFocusWithFirstTouch.prototype.handleFocus = function (event) {
        this.debouncedFocusChange.debounce(event.type);
    };
    KeepFocusWithFirstTouch.prototype.handleBlur = function (event) {
        this.debouncedFocusChange.debounce(event.type);
    };
    KeepFocusWithFirstTouch.prototype.setContainerRef = function (ref) {
        this.container = ref;
    };
    KeepFocusWithFirstTouch.prototype.setInnerRef = function (innerRef) {
        this.innerElem = innerRef;
    };
    KeepFocusWithFirstTouch.prototype.handleTouchEnd = function (event) {
        var _this = this;
        this.setState(function (_a) {
            var focused = _a.focused;
            return focused && !_this.innerElem.contains(event.target)
                ? { focused: false }
                : null;
        });
    };
    KeepFocusWithFirstTouch.prototype.componentDidMount = function () {
        document.addEventListener('touchend', this.handleTouchEnd);
    };
    KeepFocusWithFirstTouch.prototype.componentWillUnmount = function () {
        document.removeEventListener('touchend', this.handleTouchEnd);
        if (this.debouncedFocusChange) {
            this.debouncedFocusChange.cancel();
        }
        if (this.debouncedSetState) {
            this.debouncedSetState.cancel();
        }
    };
    KeepFocusWithFirstTouch.prototype.render = function () {
        var _a = this.props, children = _a.children, active = _a.active, props = __rest(_a, ["children", "active"]);
        var focused = this.state.focused;
        return (React.createElement(Container, __assign({ onFirstTouch: this.handleFirstTouch, onMouseEnter: this.handleMouseChange, onMouseLeave: this.handleMouseChange, onFocus: this.handleFocus, onBlur: this.handleBlur, ref: this.setContainerRef, innerRef: this.setInnerRef, "data-testid": "keep-focus-with-first-touch" }, props), children({ focused: focused })));
    };
    KeepFocusWithFirstTouch.defaultProps = {
        active: false,
    };
    return KeepFocusWithFirstTouch;
}(React.Component));
export default KeepFocusWithFirstTouch;
