var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { SmallText } from '../../../../common/components/Text';
import { padding, space } from '../../../../common/theme/helper';
import LogoutBlock from './LogoutBlock';
import MainBlock from './MainBlock';
import UpperBlock from './UpperBlock';
var TooltipContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 999;\n\n  top: 100%;\n  left: 50%;\n\n  transform: translateX(-50%);\n"], ["\n  position: absolute;\n  z-index: 999;\n\n  top: 100%;\n  left: 50%;\n\n  transform: translateX(-50%);\n"])));
var TooltipInnerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: ", ";\n\n  margin-top: 12px;\n\n  ", ";\n\n  background-color: ", ";\n\n  border: ", " solid ", ";\n  border-radius: 3px;\n  box-shadow: 0 3px 4px 1px ", ";\n\n  box-sizing: content-box;\n\n  &::after {\n    content: ' ';\n    position: absolute;\n    top: 0;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%) rotate(45deg);\n\n    width: 12px;\n    height: 12px;\n\n    background-color: ", ";\n    border: ", " solid\n      ", ";\n\n    border-bottom-color: transparent;\n    border-right-color: transparent;\n  }\n"], ["\n  position: relative;\n  width: ", ";\n\n  margin-top: 12px;\n\n  ", ";\n\n  background-color: ", ";\n\n  border: ", " solid ", ";\n  border-radius: 3px;\n  box-shadow: 0 3px 4px 1px ", ";\n\n  box-sizing: content-box;\n\n  &::after {\n    content: ' ';\n    position: absolute;\n    top: 0;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%) rotate(45deg);\n\n    width: 12px;\n    height: 12px;\n\n    background-color: ", ";\n    border: ", " solid\n      ", ";\n\n    border-bottom-color: transparent;\n    border-right-color: transparent;\n  }\n"])), space(10), padding(3), themeGet('colors.sys.neutral.background.default'), themeGet('borderWidths.small'), themeGet('colors.sys.neutral.border.inactive'), themeGet('colors.overlay.light'), themeGet('colors.sys.neutral.background.default'), themeGet('borderWidths.small'), themeGet('colors.sys.neutral.border.inactive'));
var TooltipBlock = styled(SmallText)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 8px 0;\n\n  &:first-of-type {\n    padding-top: 0;\n  }\n\n  &:last-child {\n    padding-bottom: 0;\n  }\n\n  &:not(:last-child) {\n    border-bottom: ", " solid\n      ", ";\n  }\n"], ["\n  padding: 8px 0;\n\n  &:first-of-type {\n    padding-top: 0;\n  }\n\n  &:last-child {\n    padding-bottom: 0;\n  }\n\n  &:not(:last-child) {\n    border-bottom: ", " solid\n      ", ";\n  }\n"])), themeGet('borderWidths.small'), themeGet('colors.sys.neutral.border.weak'));
var Tooltip = function (_a) {
    var customer = _a.customer;
    return (React.createElement(TooltipContainer, { "data-testid": "my-account-tooltip" },
        React.createElement(TooltipInnerContainer, null,
            React.createElement(TooltipBlock, null,
                React.createElement(UpperBlock, { customer: customer })),
            React.createElement(TooltipBlock, null,
                React.createElement(MainBlock, null)),
            (customer === null || customer === void 0 ? void 0 : customer.isLoggedIn) && (React.createElement(TooltipBlock, null,
                React.createElement(LogoutBlock, { firstName: customer.firstName }))))));
};
export default Tooltip;
var templateObject_1, templateObject_2, templateObject_3;
