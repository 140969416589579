var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { fontSize, size } from 'styled-system';
import themedComponent from '../../../common/components/hoc/themedComponent';
import Icon from '../../../common/components/Icon';
import { fill } from '../../../common/theme/system-utilities';
var NEW_ICON_SIZE = 24;
var StyledIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), fontSize, fill, size);
StyledIcon.defaultProps = {
    alt: '',
    fill: 'sys.neutral.icon.default',
    fontSize: 0,
    role: 'presentation',
    size: NEW_ICON_SIZE,
};
export var Account = themedComponent(function () { return React.createElement(StyledIcon, { name: "accountH24Large" }); }, function () { return React.createElement(StyledIcon, { name: "accountFfh" }); });
export var Wishlist = function (props) { return (React.createElement(StyledIcon, __assign({ name: "wishlistV2Large" }, props))); };
export var Cart = themedComponent(function () { return React.createElement(StyledIcon, { name: "cartH24V2Large" }); }, function () { return React.createElement(StyledIcon, { name: "cartFfh" }); });
var templateObject_1;
