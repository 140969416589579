var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, color, display, fontSize, height, justifyContent, lineHeight, minWidth, position, right, space, top, width, } from 'styled-system';
import KeepFocusWithFirstTouch from '../../../common/components/KeepFocusWithFirstTouch';
import { Link } from '../../../common/components/Link';
import { useMatchesViewport } from '../../../common/hooks/media';
import { useLocalization } from '../../../common/providers/LocaleProvider';
import { trackActionItemClick } from '../tracking';
import * as Icons from './ActionIcons';
var ActionContainer = styled(KeepFocusWithFirstTouch)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), position);
ActionContainer.defaultProps = {
    position: 'relative',
};
var DesktopOnlyActionContainer = styled(ActionContainer)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), display);
DesktopOnlyActionContainer.defaultProps = __assign(__assign({}, ActionContainer.defaultProps), { display: ['none', null, 'block'] });
var IconContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, display, alignItems, justifyContent, minWidth, width, height, color);
IconContainer.defaultProps = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '28px',
    width: ['42px', null, '32px'],
    height: ['42px', null, '32px'],
    color: 'sys.neutral.border.hover',
};
var IconCounter = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  min-width: 18px;\n  height: 18px;\n  font-size: 11px;\n  color: white;\n  border-radius: 100%;\n  overflow: hidden;\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  min-width: 18px;\n  height: 18px;\n  font-size: 11px;\n  color: white;\n  border-radius: 100%;\n  overflow: hidden;\n"])), position, top, right, display, alignItems, justifyContent, color);
IconCounter.defaultProps = {
    position: 'absolute',
    top: ['3px', '-3px', '-4px'],
    right: ['2px', '-5px', '-9px'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'sys.primary.background.default',
};
var ActionItemLabel = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  letter-spacing: 0.2px;\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  letter-spacing: 0.2px;\n"])), display, space, fontSize, lineHeight);
ActionItemLabel.defaultProps = {
    display: ['none', null, 'block'],
    m: 0,
    fontSize: 2,
    lineHeight: 2,
};
var ActionLink = styled(Link)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-decoration: none;\n  cursor: pointer;\n\n  &:focus > p,\n  &:hover > p {\n    ", ";\n    text-decoration: underline;\n    text-decoration-color: currentColor;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-decoration: none;\n  cursor: pointer;\n\n  &:focus > p,\n  &:hover > p {\n    ", ";\n    text-decoration: underline;\n    text-decoration-color: currentColor;\n  }\n"])), color);
ActionLink.defaultProps = {
    styled: false,
    color: 'sys.primary.background.default',
};
var ActionItem = function (_a) {
    var label = _a.label, url = _a.url, icon = _a.icon, count = _a.count, children = _a.children, _b = _a.countThreshold, countThreshold = _b === void 0 ? 1 : _b, desktopOnly = _a.desktopOnly, isNewIcon = _a.isNewIcon, showTooltip = _a.showTooltip;
    // INFO:
    // 1) Matching the media query allows us to disable
    //    ActionItem active state and children on mobile/tablet
    // 2) `defaultMatches` is set to false in order to avoid the default
    //    behaviour which is always match if you render on the server
    var matchesTablet = useMatchesViewport(true, false, false).matches.matchesTablet;
    var _c = useLocalization(), t = _c.t, prefix = _c.prefix;
    var IconComponent = Icons[icon];
    // use css solution to avoid jump in browser when using `useMatchesViewport`
    var Container = desktopOnly ? DesktopOnlyActionContainer : ActionContainer;
    return (React.createElement(Container, { "data-testid": "action-item", active: matchesTablet && showTooltip }, function (_a) {
        var focused = _a.focused;
        return (React.createElement(React.Fragment, null,
            React.createElement(ActionLink, { "data-testid": "action-item-link", "aria-labelledby": "action-item-label-".concat(label), href: prefix.t(url), onClick: function () { return trackActionItemClick(t(label)); } },
                React.createElement(IconContainer, null,
                    React.createElement(IconComponent, { key: icon, isNewIcon: isNewIcon }),
                    count >= countThreshold && (React.createElement(IconCounter, { "data-testid": "".concat(icon, "-badge-counter") }, count))),
                React.createElement(ActionItemLabel, { id: "action-item-label-".concat(label), "data-testid": "action-item-label" }, t(label))),
            typeof children === 'function' &&
                children({
                    shouldShowChildren: matchesTablet,
                    isActionItemActive: focused,
                })));
    }));
};
export default ActionItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
