var Debounce = /** @class */ (function () {
    function Debounce(fn, delay, context) {
        if (delay === void 0) { delay = 0; }
        if (context === void 0) { context = null; }
        this.fn = fn;
        this.delay = delay;
        this.context = context;
    }
    Debounce.prototype.debounce = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () { return _this.fn.apply(_this.context, args); }, this.delay);
    };
    Debounce.prototype.cancel = function () {
        clearTimeout(this.timeout);
    };
    return Debounce;
}());
export default Debounce;
