var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { marginTop, space } from 'styled-system';
import { Link } from '../../../../common/components/Link';
import { useLocalization } from '../../../../common/providers/LocaleProvider';
import { listStyle } from '../../../../common/theme/system-utilities';
import { trackMyAccountTooltipClick } from '../../tracking';
var List = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), space, listStyle);
var listStyles = {
    m: 'sp_0',
    p: 'sp_0',
    listStyle: 'none',
};
var ListItem = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &:not(:first-of-type) {\n    ", "\n  }\n"], ["\n  &:not(:first-of-type) {\n    ", "\n  }\n"])), marginTop);
var mainBlockItems = [
    {
        text: 'h24_my_overview',
        url: 'h24_my_overview_url',
    },
    {
        text: 'h24_my_orders',
        url: 'h24_my_orders_url',
    },
    {
        text: 'h24_help_contact',
        url: 'h24_help_contact_url',
    },
];
var MainBlock = function () {
    var _a = useLocalization(), t = _a.t, p = _a.p;
    return (React.createElement(List, __assign({}, listStyles), mainBlockItems.map(function (item) { return (React.createElement(ListItem, { key: item.text, mt: "sp_8" },
        React.createElement(Link, { href: p.t(item.url), onClick: function () { return trackMyAccountTooltipClick(t(item.text)); } }, t(item.text)))); })));
};
export default MainBlock;
var templateObject_1, templateObject_2;
