var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { getTrackerProps } from '../../../../common/tracking/getTrackerProps';
import { useLocalization } from '../../../../common/providers/LocaleProvider';
import { Link } from '../../../../common/components/Link';
import { HOTJAR_SUPPRESS_ATTRS } from '../../../../common/constants/hotjar';
var LogoutBlock = function (_a) {
    var not = _a.not, logoutURL = _a.logoutURL, firstName = _a.firstName;
    var _b = useLocalization(), r = _b.r, prefix = _b.prefix;
    return (React.createElement(Link, __assign({}, getTrackerProps(__assign(__assign({}, HOTJAR_SUPPRESS_ATTRS), { href: prefix.t(logoutURL), dangerouslySetInnerHTML: {
            __html: r.t(not, {
                username: firstName,
            }),
        } }), {
        event: 'headerTracking',
        eventData: {
            placement: 'userAccountIcon',
            labelIdentifier: not,
            listIndex: 0,
            listLength: 1,
        },
    }))));
};
LogoutBlock.defaultProps = {
    not: 'h24_not_your_account',
    logoutURL: 'h24_logout_url',
};
export default LogoutBlock;
