var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { borderColor, borderRadius, borderStyle, borderWidth, display, size, space, } from 'styled-system';
import { Button } from '../../../common/components/Button';
import Icon from '../../../common/components/Icon';
import { fill } from '../../../common/theme/system-utilities';
import { trackHamburgerClick } from '../tracking';
var ICON_SIZE = 46;
var MenuButtonContainer = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), borderColor, borderRadius, borderStyle, borderWidth, display, space);
MenuButtonContainer.defaultProps = {
    kind: 'bare',
    borderColor: 'sys.neutral.border.default',
    borderRadius: 'small',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'inline-flex',
    mr: ['sp_16', null, 'sp_20'],
};
var BurgerIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), fill, size);
BurgerIcon.defaultProps = {
    fill: 'sys.neutral.icon.active',
    size: ICON_SIZE,
};
var MenuButton = function (_a) {
    var onClick = _a.onClick;
    return (React.createElement(MenuButtonContainer, { "data-testid": "hamburger-button", "aria-label": "menu", onClick: function () {
            trackHamburgerClick();
            onClick();
        } },
        React.createElement(BurgerIcon, { name: "burger", dataTestId: "hamburger-button-icon", ariaHidden: true })));
};
export default MenuButton;
var templateObject_1, templateObject_2;
