var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import name from 'react-display-name';
export var withFirstTouch = function (Component) {
    var WithFirstTouch = /** @class */ (function (_super) {
        __extends(WithFirstTouch, _super);
        function WithFirstTouch(props) {
            var _this = _super.call(this, props) || this;
            _this.isFirstTouch = true;
            _this.isTouchEnabled = false;
            _this.onClick = _this.onClick.bind(_this);
            _this.onTouchStart = _this.onTouchStart.bind(_this);
            return _this;
        }
        WithFirstTouch.prototype.onTouchStart = function (event) {
            this.isTouchEnabled = true;
            if (this.props.onTouchStart) {
                this.props.onTouchStart(event);
            }
        };
        WithFirstTouch.prototype.onClick = function (event) {
            if (this.isTouchEnabled === true && this.isFirstTouch === true) {
                this.props.onFirstTouch(event);
                this.isFirstTouch = false;
            }
            else if (this.props.onClick) {
                this.props.onClick(event);
            }
        };
        WithFirstTouch.prototype.resetFirstTouch = function () {
            this.isFirstTouch = true;
        };
        WithFirstTouch.prototype.render = function () {
            var _a;
            var _b = this.props, onClick = _b.onClick, onTouchStart = _b.onTouchStart, onFirstTouch = _b.onFirstTouch, innerRef = _b.innerRef, children = _b.children, refKey = _b.refKey, props = __rest(_b, ["onClick", "onTouchStart", "onFirstTouch", "innerRef", "children", "refKey"]);
            return React.createElement(Component, __assign((_a = {}, _a[refKey] = innerRef, _a.onClick = this.onClick, _a.onTouchStart = this.onTouchStart, _a), props), children);
        };
        WithFirstTouch.displayName = "withFirstTouch(".concat(name(Component), ")");
        WithFirstTouch.defaultProps = {
            refKey: 'ref',
        };
        return WithFirstTouch;
    }(React.Component));
    return isStringElement(Component)
        ? WithFirstTouch
        : hoistNonReactStatics(WithFirstTouch, Component);
};
function isStringElement(Component) {
    return typeof Component === 'string';
}
